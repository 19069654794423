<template>
    <div class="affiliate-signup common-edit" style="max-width:1000px;margin:auto">
        <form v-on:submit.prevent="save()">
            <md-card>
                <md-card-header>
                    <h2>Affiliate Signup</h2>
                </md-card-header>
                <md-card-content>
                    <md-input-container>
                        <label for>Affiliate Id</label>
                        <md-input readonly v-model="affiliateId"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for>Install Script</label>
                        <md-textarea readonly rows="1" v-model="scriptContent"></md-textarea>
                    </md-input-container>
                    <md-input-container class="md-has-value">
                        <label for>Selected Category</label>
                        <md-input type="hidden"></md-input>
                        <div style="flex:1">
                            <template v-for="(category,ix) in selectedCategories">
                                <div :key="ix" style="display:flex;">
                                    <category-select style="flex:1" v-model="selectedCategories[ix]"></category-select>
                                    <md-button @click="deleteCategory(ix)" style="margin:0;" v-if="selectedCategories.length>1">
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </div>
                            </template>
                        </div>
                        <md-button @click="addCategory" style="margin:0;">
                            <md-icon>add</md-icon>
                        </md-button>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <submit :disabled="errors.any()" icon="save" text="Save"></submit>
                </md-card-actions>
            </md-card>
        </form>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import CategorySelect from "@/components/Category/CategorySelect";
import Submit from "@/components/Submit";
import { mapGetters } from "vuex";
export default {
    components: {
        Edit,
        CategorySelect,
        Submit
    },

    data() {
        return {
            affiliateId: "",
            selectedCategories: [undefined]
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        }),
        scriptContent() {
            return `
          <iframe src="${location.protocol}//${location.host}?affiliateId=${this.affiliateId}"> </iframe>
        `;
        }
    },
    methods: {
        addCategory() {
            this.selectedCategories.push(undefined);
        },
        deleteCategory(ix) {
            console.info(ix);
            this.selectedCategories.splice(ix, 1);
            console.info(this.selectedCategories);
        },
        async getAffiliateId() {
            let result = await this.$store.dispatch("crud/get", {
                api: "affiliate/getAffiliateId"
            });
            this.affiliateId = result;
        },
        async save() {
            let result = await this.$store.dispatch("crud/put", {
                api: "affiliate/updateAffiliateCategories",
                data: {
                    categoryIds: this.selectedCategories
                }
            });
            this.$store.commit("setMessage", "Save Success");
            await this.$store.dispatch("user/refreshProfile");
            this.selectedCategories = this.user.affiliateCategories;
            if (this.selectedCategories.length == 0) {
                this.selectedCategories.push(undefined);
            }
        }
    },
    async mounted() {
        await this.getAffiliateId();
        this.selectedCategories = this.user.affiliateCategories;
        if (this.selectedCategories.length == 0) {
            this.selectedCategories.push(undefined);
        }
    }
};
</script>